const noJSON = {
  //Languages
  'Language switcher': 'Språkbytter',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Finnish: 'Finsk',
  Swedish: 'Svensk',

  //login
  'Sign in': 'Logg inn',
  'Email address': 'Epostadresse',
  Password: 'Husk meg',
  'Remember me': 'Remember me',
  'Lost password?': 'Mistet Passord?',
  'The email provided should be a valid email address':
    'E-postadressen som oppgis skal være en gyldig e-postadresse',
  'The email field is required': 'E-postfeltet er obligatorisk',
  'The password field is required': 'Passordfeltet er obligatorisk',
  'Fill in the fields below to sign into your account.':
    'Fyll ut feltene nedenfor for å logge på kontoen din.',

  //Menu
  Overview: 'Oversikt',
  Dashboard: 'Dashbord',
  Analytics: 'Analytics',
  Financial: 'Finansiell',
  Management: 'Ledelse',
  Monitoring: 'Overvåkning',
  'Camera feed': 'Kamerafeed',
  Facilities: 'Fasiliteter',
  Pricing: 'Prissetting',
  Matching: 'Matchende',
  'Parking sessions': 'Parkering økter',
  Allowlist: 'Tillatelsesliste',
  'Event log': 'Hendelsesloggen',
  Subscriptions: 'Abonnementer',
  Customers: 'Kunder',
  'End-Users': 'Sluttbrukere',
  Team: 'Team',
  Help: 'Hjelp',
  FAQ: 'FAQ',
  Support: 'Brukerstøtte',
  Documentation: 'Dokumentasjon',
  Configuration: 'Konfigurasjon',
  Operators: 'Operatører',
  'ID-carriers': 'ID-bærere',
  System: 'System',
  Users: 'Brukere',
  SubOperators: 'Underoperatører',
  Logout: 'Logg ut',

  'Search for license plate, user, transaction etc.':
    'Søk etter nummerskilt, bruker, transaksjon etc.',
  'Search here': 'Søk her',
  'Recent searches': 'Nylige søk',
  'Saved searches': 'Lagrede søk',
  'User results': 'Brukerresultater',

  'Licence plates & vehicles': 'Nummerskilt og kjøretøy',

  //Dashboard
  'All facilities': 'Alle fasiliteter',
  Period: 'Periode',
  Today: 'I dag',
  Yesterday: 'I går',
  'This week': 'Denne uka',
  'Last week': 'Forrige uke',
  'This month': 'Denne måneden',
  'Last month': 'Forrige måned',
  'Custom period': 'Egendefinert periode',
  'All time': 'Hele tiden',
  'Packing facility': 'Pakkeanlegg',
  Occupancy: 'Belegg',
  'Facility overview': 'Oversikt over anlegget',
  'Facility area': 'Anleggsområde',
  'In order to see the ocuppancy percent, you need to fill in the capacity field':
    'For å se beleggsprosenten må du fylle ut kapasitetsfeltet',
  Revenue: 'Inntekter',
  Sales: 'Salg',
  up: 'opp',
  down: 'ned',
  View: 'Utsikt',
  'n/a': 'n/a',

  //Analytics Page
  'Occupancy & sessions': 'Belegg og økter',
  'Average occupancy': 'Gjennomsnittlig belegg',
  'Average session': 'Gjennomsnittlig økt',
  Sessions: 'Økter',
  'in total': 'totalt',
  weekly: 'ukentlig',
  daily: 'daglig',
  'per hour': 'per time',
  'Compared to..': 'Sammenlignet med..',
  'from last month': 'fra forrige måned',
  'settlement types/cashflow': 'oppgjørstyper/kontantstrøm',
  'On location': 'På stedet',
  Invoice: 'Faktura',
  Online: 'på nett',
  Unpaid: 'Ubetalt',
  Income: 'Inntekt',
  'Yearly income (estimated)': 'Årlig inntekt (estimert)',
  'Per month': 'Per måned',
  'Per year': 'Per år',
  'Per day': 'Per dag',
  'Per hour': 'Per time',
  'Active subscribers': 'Aktive abonnenter',
  'Subscription revenue': 'Abonnementsinntekter',
  'Chunk rate': 'Chunk rate',
  Description: 'Beskrivelse',

  //Facility access
  'Facility access': 'Tilgang til fasiliteter',
  'Search in LP,facilities or group name...':
    'Søk i LP, fasiliteter eller gruppenavn...',
  'Add new': 'Legg til ny',
  'Vehicle group': 'Kjøretøy gruppe',
  Status: 'Status',
  Actions: 'Handlinger',
  None: 'Ingen',
  'Choose vehicle groups': 'Velg kjøretøygrupper',

  'View allowlist': 'Se godkjenningslisten',
  'Edit allowlist': 'Rediger godkjenningslisten',
  'Delete allowlist': 'Slett godkjenningsliste',

  facilities: 'fasiliteter',
  Add: 'Legge til',
  Cancel: 'Avbryt',
  Close: 'Lukk',
  'Add allowlist': 'Legg til godkjenningslisten',
  'Add vehicles': 'Legg til kjøretøy',
  'License plates': 'Bilskilt',
  or: 'eller',
  'Import from CSV': 'Importer fra CSV',
  'Choose facilities': 'Velg fasiliteter',
  'All facilitites': 'Alle fasiliteter',
  'View group': 'Vis gruppe',
  'Group name': 'Gruppenavn',
  Delete: 'Slett',
  'No facilities': 'Ingen fasiliteter',
  Edit: 'Muokata',
  Resolution: 'Vedtak',
  Hourly: 'Hver time',
  Daily: 'Daglig',
  'Subscription revenue trend': 'Trend for abonnementsinntekter',
  'Group name is a required field': 'Gruppenavn er et obligatorisk felt',
  'Add license plates': 'Legg til bilskilt',

  '"Syncing" or "Out of sync" lists may take a few minutes to be synced. To get the latest status refresh:':
    '"Synkronisering" eller "Ute synkronisert"-lister kan ta noen minutter å bli synkronisert. For å få siste statusoppdatering:',
  'No results for': 'Ingen resultater for',
  //update

  'Search operator...': 'Søkeoperatør...',
  'Search...': 'Søk...',
  Name: 'Navn',
  'Account Type': 'Kontotype',
  Email: 'E-post',
  phone: 'telefon',
  Account: 'Regnskap',
  'Mobile phone': 'Mobiltelefon',
  User: 'Bruker',
  'User type': 'Brukertype',
  Created: 'Opprettet',
  'Last login': 'Siste innlogging',
  Operator: 'Operatør',
  'Subscription package': 'Abonnementspakke',
  Vehicles: 'Kjøretøy',
  'Subscription period': 'Abonnementsperiode',
  'Subscription code': 'Abonnementskode',
  Price: 'Pris',
  Facility: 'Anlegget',
  'Car park': 'Bilparkering',
  'Parking started': 'Parkering startet',
  'Parking ended': 'Parkeringen ble avsluttet',
  'Internal ID': 'Intern ID',
  'Parking attribute': 'Parkeringsattributt',
  ID: 'ID',
  Registration: 'Registrering',
  'Search in name,date,category,location or details...':
    'Søk i navn, dato, kategori, sted eller detaljer...',
  'Date & time': 'Dato tid',
  Catagory: 'Kategori',
  Location: 'plassering',
  Details: 'Detaljer',
  Warnings: 'Advarsler',
  'SwappAccess Admin Dashboard': 'SwappAccess Admin Dashboard',
  'Probability rate': 'Sannsynlighetsrate',
  'Unread license plates': 'Ulest bilskilt',
  'Previous period': 'Forrige periode',
  'Area(Tariff ID)': 'Område (tariff-ID)',
  'Search in name, subscription ID or subscription article code ...':
    'Søk i navn, abonnements-ID eller abonnementsartikkelkode ...',
  'Search by name,role,email...': 'Søk etter navn, rolle, e-post...',

  'Email Address': 'Epostadresse',
  'The site is currently down for maintenance':
    'Siden er for tiden nede for vedlikehold',
  'We apologize for any inconveniences caused':
    'Vi beklager eventuelle ulemper dette medfører',
  Phone: 'Telefon',

  "The page you were looking for doesn't exist.":
    'Siden du lette etter eksisterer ikke.',

  "It's on us, we moved the content to a different page. The search below should help!":
    'Det er på oss, vi flyttet innholdet til en annen side. Søket nedenfor burde hjelpe!',
  'Search terms here...': 'Søkeord her...',
  Search: 'Søk',

  'Go back': 'Gå tilbake',
  'Go to homepage': 'Gå til hjemmesiden',
  'There was an error, please try again later':
    'Det oppsto en feil, prøv igjen senere',
  'The server encountered an internal error and was not able to complete your request':
    'Serveren oppdaget en intern feil og kunne ikke fullføre forespørselen din',

  'Refresh view': 'Oppdater visningen',
  'Tokyo White React Typescript Admin Dashboard':
    'Tokyo White React Typescript Admin Dashboard',
  'High performance React template built with lots of powerful MUI (Material-UI) components across multiple product niches for fast & perfect apps development processes.':
    'Høyytelses React-mal bygget med mange kraftige MUI (Material-UI)-komponenter på tvers av flere produktnisjer for raske og perfekte apputviklingsprosesser.',
  'There is no data available for this graph':
    'Det er ingen data tilgjengelig for denne grafen',
  'Coming soon': 'Kommer snart',
  "There's still work to be done on this feature, before you'll be able to see it.":
    'Det gjenstår fortsatt arbeid med denne funksjonen før du kan se den.',
  'Weekly revenue': 'Ukentlig inntekt',
  'Reports for this weeks revenue stream':
    'Rapporter for denne ukens inntektsstrøm',
  EasyPark: 'EasyPark',
  Microlog: 'Mikrolog',
  'View complete report': 'Se fullstendig rapport',
  Alerts: 'Varsler',
  'Camera status': 'Kamerastatus',
  'Barrier status': 'Barrierestatus',
  'Ticket machine status': 'Billettautomatstatus',
  On: 'På',
  Off: 'Av',
  'Skip menu filtering': 'Hopp over menyfiltrering',
  'Last year': 'I fjor',
  'Traffic overview': 'Trafikkoversikt',
  'License plate': 'Bilskilt',
  Date: 'Dato',
  Started: 'Startet',
  Ended: 'Endte',
  Duration: 'Varighet',
  'Subscriptions active / Max': 'Abonnementer aktive / Maks',
  Affinity: 'Affinitet',
  'Delete user': 'Slett bruker',
  Role: 'Rolle',
  Save: 'Lagre',
  'Per subscription': 'Per abonnement',
  'Last updated': 'Sist oppdatert',
  'Something went wrong': 'Noe gikk galt',
  'Edit vehicle group': 'Rediger kjøretøygruppe',
  'View vehicle group': 'Se kjøretøygruppe',
  'Session trend': 'Sesjonstrend',
  Transactions: 'Transaksjoner',
  Contracts: 'Kontrakter',

  'Forgot password': 'Glemt passord',
  'Enter your email address below and we will send you a password reset link':
    'Skriv inn e-postadressen din nedenfor, så sender vi deg en lenke for tilbakestilling av passord',
  Submit: 'Sende inn',

  'Reset password': 'tilbakestille passord',
  'Write and confirm your new password below':
    'Skriv og bekreft ditt nye passord nedenfor',
  'New password': 'Nytt passord',
  'Confirm password': 'Bekreft passord',
  'New password field is required': 'Nytt passordfelt kreves',
  'Confirm password field is required': 'Bekreft passordfeltet er påkrevd',
  'Password confirmation must be the same as the password field':
    'Passordbekreftelsen må være den samme som passordfeltet',
  'We cannot connect to the backend at this point. We apologize for the inconvenience':
    'Vi kan ikke koble til backend på dette tidspunktet. Vi beklager ulempene dette medfører.',
  Cameras: 'Kameraer',
  'Register camera': 'Registrer kamera',
  'Available cameras': 'Tilgjengelige kameraer',
  'Installed cameras': 'Installerte kameraer',
  'Register lane': 'registerLane',
  'Add lane': 'Legg til Lane',
  'Adding lane': 'Legger til Lane',
  'Select type': 'Velg type',
  'Serial No.': 'Seriell nr.',
  'IP address': 'IP adresse',
  Username: 'Brukernavn ',
  Notes: 'Merknader',
  'The name field is required': 'Navnefeltet er påkrevd',
  'The username field is required': 'Brukernavnfeltet er påkrevd',
  'The Serial No. field is required': 'Seriell nr. Felt er påkrevd',
  'The IP address field is required': 'IP -adressefeltet er påkrevd',
  'The notes field is required': 'Notatfeltet er påkrevd',
  'Please provide valid IP address': 'Oppgi gyldig IP -adresse',
  'Assigned to lane': 'Tildelt Lane',
  'New camera registration': 'Ny kameraregistrering',
  'Add entry lane': 'Legg til inngangsfelt',
  'Add exit lane': 'Legg til avkjørsel',
  Entry: 'Inngang',
  Exit: 'Utgang',
  Lanes: 'Baner',
  'At least one type must be selected': 'Minst én type må velges',
  Connect: 'Tilordne',
  'Select front camera': 'Velg frontkamera',
  'Select back camera': 'Velg ryggkamera',

  'Upload image': 'Last opp bilde',

  'Front camera': 'Frontkamera',
  'Back camera': 'Bakkamera',

  'New entry lane': 'Ny innfartsvei',
  'New exit lane': 'Ny utkjøringsvei',

  'Verification code from email': 'Verifieringskod från e-post',
  'Verification code field is required': 'Bekreftelseskodefelt er obligatorisk',
  'We have sent you an email with a code fore resetting password. Please add the code below':
    'Vi har sendt deg en e-post med en kode for tilbakestilling av passord. Vennligst legg til koden nedenfor',

  'Delete lane': 'Slett kjørefelt',

  "Are you sure you want to delete the lane '{{lane}}'?":
    "Er du sikker på at du vil slette feltet '{{lane}}'?",

  Yes: 'Ja',
  No: 'Nei',

  'Access terminals': 'Tilgang til terminaler',
  'Add access terminal': 'Legg til tilgangsterminal',
  Prompt: 'Spør',

  Port: 'Havn',
  Camera: 'Kamera',
  'The Port field is required': 'Port-feltet er obligatorisk',
  'The registration number is required':
    'Registreringsnummeret er obligatorisk',
  'Access terminal': 'Tilgangsterminal',
  'Connected terminal': 'Tilkoblet terminal',
  'Edit camera': 'Rediger kamera',
  "Are you sure you want to delete the camera '{{camera}}' ?":
    "Er du sikker på at du vil slette kameraet '{{camera}}'?",
  'Delete camera': 'Slett kamera',

  'Edit access terminal': 'Rediger tilgangsterminal',
  "Are you sure you want to delete the access terminal '{{accessTerminal}}' ?":
    "Er du sikker på at du vil slette tilgangsterminalen '{{accessTerminal}}'?",
  'Replace image': 'Erstatt bilde',

  'Background color': 'Bakgrunnsfarge',
  'Text color': 'Tekstfarge',

  'Hide heading': 'Skjul overskrift',
  'Hide terminal name': 'Skjul terminalnavn',

  Relays: 'Reléer',
  'Register relay': 'Registrer relé',
  'New relay registration': 'Ny stafettregistrering',
  Uninstall: 'Avinstaller',
  'Uninstall access terminal': 'Avinstaller tilgangsterminal',
  'This action will completely delete the access terminal. Would to like to remove it? yes/no':
    'Denne handlingen vil slette tilgangsterminalen fullstendig. Vil du fjerne den? Ja Nei',

  'Update connection': 'Oppdater tilkobling',
  'Edit connection': 'Rediger tilkobling',
  'Edit IP': 'Rediger IP',
  'Edit relay': 'Rediger stafett',
  'Delete relay': 'Slett relé',
  Remove: 'Fjerne',
  'Terminal details': 'Terminaldetaljer',
  'Are you sure you want to delete the Relay':
    'Er du sikker på at du vil slette Relay',
  'Camera details': 'Kameradetaljer',
  'Relay details': 'Relédetaljer',
  Relay: 'Stafett',
  'Access terminal connection': 'Tilgang til terminaltilkobling',
  'Relay port': 'Reléport',
  Connection: 'Forbindelse',
  'Unexpected error in backend. [{{errorStatus}}]':
    'Uventet feil i backend. [{{errorStatus}}]',

  OK: 'Greit',
  'Camera unreachable': 'Kameraet er utilgjengelig',
  NetworkRelayUnreachable: 'Nettverksrelé kan ikke nås',
  'Camera error': 'Kamerafeil',
  Unknown: 'Ukjent',
  'Network relay error': 'Nettverksreléfeil',
  'Network relay unreachable': 'Nettverksrelé kan ikke nås',
  Type: 'Type',

  'Access url': 'ENccess url',
  'Disconnect camera': 'Koble fra kameraet',
  'Disconnect relay': 'Koble fra reléet',
  'Are you sure you want to disconnect the {{deviceName}} - {{ipAddress}} {{deviceType}}?':
    'Er du sikker på at du vil koble fra {{Devicename}} - {{ipAddress}} {{{Devicetype}}?',

  'Select date': 'Velg dato',
  'Ended at': 'Sluttet kl',
  'Started at': 'Startet kl',
  'Select facility': 'Velg anlegg',

  'Search by license plate…': 'Søk etter skilt...',
  'Start date range': 'Startdatoperiode',
  'End date range': 'Sluttdatoperiode',

  at: 'kl.',

  Direction: 'Retning',
  Issues: 'Problemer',
  Solve: 'Løse',
  'Registered issues in': 'Registrerte problemer i',

  Country: 'Land',
  Confirm: 'Bekrefte',
  Drop: 'Miste',

  'Enable ANPR': 'Aktiver ANPR',
  'Disable ANPR': 'Deaktiver ANPR',
  'Send ANPR events': 'Send ANPR-hendelser',
  'Are you sure you want to logout?': 'Er du sikker på at du vil logge ut?',
  'Flagged parkings': 'Flagget parkeringer',
  In: 'In',
  Out: 'Ute',
  Norway: 'Norge',

  'Install kiosks': 'Installer kiosker',
  Install: 'Installere',
  'Edit kiosk': 'Rediger kiosk',

  'Parking session': 'Parkering økt',
  'Entry time': 'Inngangstid',
  'Exit time': 'Utgangstid',

  'Before trying to resolve issues in flagged parking, try resolving all the issues under':
    'Før du prøver å løse problemer i flagget parkering, prøv å løse alle problemene under',
  'Fixing those is very likely to fix these issues too.':
    'Å fikse disse vil sannsynligvis også løse disse problemene.',
  'Solve recognition issue': 'Løs gjenkjenningsproblem',
  'Registration number': 'Registreringsnummer',
  Configure: 'Konfigurer',

  'Vehicle groups': 'kjøretøygrupper',
  'Membership plans': 'Medlemskapsplaner',
  'Edit membership plan': 'Rediger medlemskapsplan',
  'Add membership plan': 'Legg til medlemskapsplan',
  'Company name': 'selskapsnavn',
  'Period days': 'Periode dager',
  Memberships: 'Medlemskap',
  'Plan name': 'Planens navn',
  Comments: 'Kommentarer',
  'Are you sure you want to approve': 'Er du sikker på at du vil godkjenne?',
  'Are you sure you want to reject': 'Er du sikker på at du vil avvise?',
  'Sales period': 'Salgsperiode',
  'Max concurrent uses': 'Maks samtidig bruk',
  'Vat rate': 'momssats',
  Action: 'Handling',
  private: 'privat',
  'Are you sure you want to make': 'Er du sikker på at du vil lage',
  public: 'offentlig',
  'Are you sure you want to': 'Er du sikker på at du vil',
  publish: 'publisere',
  'Are you sure you want to cancel subscription for':
    'Er du sikker på at du vil kansellere abonnementet for',
  'Add parking terminal': 'Legg til parkeringsautomat',
  'Invoicing reference': 'Fakturareferanse',
  'Parking terminal': 'Parkeringsterminal',
  'Edit parking terminal': 'Rediger parkeringsautomat',
  'The invoicing reference field is required':
    'Fakturareferansefeltet er påkrevd',
  'Are you sure you want to install parking terminal? yes/no':
    'Er du sikker på at du vil installere parkeringsautomat? ja/nei',
  'Are you sure you want to uninstall parking terminal? yes/no':
    'Er du sikker på at du vil avinstallere parkeringsautomat? ja/nei',
  'Add subscription': 'Legg til abonnement',
  'Add car park areas': 'Legg til parkeringsområder',
  'Add to subscription': 'Legg til abonnement',
  'Create subscription': 'Opprett abonnement',
  'Are you sure you want to remove': 'Er du sikker på at du vil fjerne',
  subscription: 'abonnement',
  VAT: 'MVA',
  'Parking areas': 'Parkeringsområder',
  Publish: 'Publiser',
  'Cancel subscription': 'Avbryt abonnement',
  'Remove subscription': 'Fjern abonnement',
  'Make private': 'Gjør privat',
  'Make public': 'Gjør offentlig',
  'Save subscription': 'Lagre abonnement',
  remove: 'fjern',
  cancel: 'avbryt',
  'Invoice reference': 'Fakturareferanse',
  'Published at': 'Publisert den',
  Accessibility: 'Tilgjengelighet',
};

export const translatedStrings = Object.keys(noJSON);

export type TranslatableString = keyof typeof noJSON;

export default noJSON;
